import React, { useEffect, useState } from 'react';
import "./ContactForm.css";
import emailjs from '@emailjs/browser';
import toast from 'react-hot-toast';


const ContactForm = () => {
  const [formData, setFormData] = useState({
    firstname: '',
    lastname: '',
    email: '',
    query:''
  });

  function emailInit(){
    
    emailjs.init(
        {
            publicKey: '3EOyVF74adZGCKNk7',
            // Do not allow headless browsers
            blockHeadless: true,
            blockList: {
              // Block the suspended emails
              list: ['foo@emailjs.com', 'bar@emailjs.com'],
              // The variable contains the email address
              watchVariable: 'userEmail',
            },
            limitRate: {
              // Set the limit rate for the application
              id: 'app',
              // Allow 1 request per 10s
              throttle: 10000,
            },
          }
    )
  }
  useEffect(() => {
    emailInit();
  },[]);


  function handleChange(event){
    const { name, value } = event.target;
    setFormData((prevData) => {
        return {
            ...prevData,
            [name]:value
        }
    });
  };

  function handleSubmit(event){
    event.preventDefault();
    
    

    const { firstname, lastname, email,query } = formData;

    const templateParams = {
      from_name: firstname+" "+lastname,
      from_email: email,
      query: query
    };

    emailjs.send(
      'service_ip3652g',   // Replace with your Service ID
      'template_r9q17na',  // Replace with your Template ID
      templateParams
            
    )
    .then((response) => {
        
      console.log('SUCCESS!', response.status, response.text);
      toast.success("Email Sent Successfully",{
        duration: 5000
      });
      
      
    })
    .catch((err) => {
      console.error('FAILED...', err);
      toast.error('Failed to send the message, please try again later.')
    });
  };
    
  

  return (

        <div className='mt-[3rem] bg-silver bg-opacity-15 text-left pl-[7rem] pt-[1.7rem] cursor-default top-container'>
            <div className='text-4xl font-semibold mb-[1rem]'>
                Contact Us for more info
            </div>
             <form onSubmit={handleSubmit}>
                <div className=''>
                    <p className='mb-[1rem] contact'>
                        First Name
                    </p>
                    
                    <input
                        type="text"
                        name="firstname"
                        value={formData.firstname}
                        onChange={handleChange}
                        placeholder='Enter Your First Name'
                        required
                        className='input-tag'
                        
                    />
                    
                </div>
                <div>
                    <p className='mb-[1rem] mt-[1rem] contact'>
                        Last Name
                    </p>
                    <input
                        type="text"
                        name="lastname"
                        value={formData.lastname}
                        onChange={handleChange}
                        placeholder='Enter Your Last Name'
                        required
                        className='input-tag'
                    />
                    
                </div>
                <div>
                    <p className='mb-[1rem] mt-[1rem] contact'>
                        Email ID:
                    </p>
                    <input
                        type="email"
                        name="email"
                        value={formData.email}
                        placeholder='Enter Your Email ID'
                        onChange={handleChange}
                        required
                        className='input-tag'
                    />
                
                </div>
                <div>
                    <p className='mb-[1rem] mt-[1rem] contact'>
                        Ask Your Query
                    </p>
                    <textarea
                        type="text"
                        name="query"
                        value={formData.query}
                        placeholder='Ask your query in detail'
                        onChange={handleChange}
                        required
                        className='input-tag-query'
                    />
                
                </div>
                <button className='mt-[2.5rem] btn-pink mb-[3rem] rounded-md' id="btn" type="submit">Submit</button>
                </form>
        </div>
    
  );
};

export default ContactForm;
