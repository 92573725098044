import React, { useState } from 'react';
import "./Navbar.css";
import { Link } from 'react-scroll';
import logo from "../pics/plutus green.png";

const Navbar = () => {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  const handleMobileMenuToggle = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  return (
    <div className='navbar w-[100vw]'>
      <div className="flex flex-row h-[9vh] justify-between items-center">
        <div className='flex items-center w-[60rem] pl-[2rem]'>
          <img className="logo" src={logo} alt="Plutus Logo" />
          <p className='text-xl text-bold'>Plutus</p>
        </div>
        <div className='hidden md:flex'>
          <ul className="web_navbar gap-[8rem] pr-[8rem]">
            <li className='nav-item'>
              <Link to="home" smooth={true} duration={500}>
                Home
              </Link>
            </li>
            <li className='nav-item'>
              <Link to="Services" smooth={true} duration={500}>
                Services
              </Link>
            </li>
            <li className='nav-item'>
              <Link to="Register" smooth={true} duration={500}>
                Register
              </Link>
            </li>
            <li className='nav-item'>
              <Link to="Contact" smooth={true} duration={500}>
                Contact
              </Link>
            </li>
          </ul>
        </div>
        <div className='md:hidden pr-[2rem]'>
          <button className="mobile-menu-button" onClick={handleMobileMenuToggle}>
            <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16m-7 6h7"></path>
            </svg>
          </button>
        </div>
      </div>
      {isMobileMenuOpen && (
        <div className='mobile-menu md:hidden'>
          <ul className="web_navbar flex flex-col gap-4">
            <li className='nav-item'>
              <Link to="home" smooth={true} duration={500} onClick={handleMobileMenuToggle}>
                Home
              </Link>
            </li>
            <li className='nav-item'>
              <Link to="Services" smooth={true} duration={500} onClick={handleMobileMenuToggle}>
                Services
              </Link>
            </li>
            <li className='nav-item'>
              <Link to="Register" smooth={true} duration={500} onClick={handleMobileMenuToggle}>
                Register
              </Link>
            </li>
            <li className='nav-item'>
              <Link to="Contact" smooth={true} duration={500} onClick={handleMobileMenuToggle}>
                Contact
              </Link>
            </li>
          </ul>
        </div>
      )}
    </div>
  );
};

export default Navbar;