import React, { useEffect, useRef } from 'react';
import Typed from 'typed.js';

import intro from "../pics/Intro.png"
import "./Intro.css"
import { Link } from 'react-scroll';

const Intro = () => {

    const typedRef = useRef(null);

    useEffect(() => {
      const options = {
        strings: [
          " optimize operations.",
          "driving growth and innovation.",
          "enhance customer experiences."],
        loop: true,
        typeSpeed: 40,
        backSpeed: 30,
        backDelay: 1000, // Increased back delay
        startDelay: 500,
        showCursor:false,
        
      };
  
      // el refers to the span element containing the typed text
      typedRef.current = new Typed('.role', options);
  
      // Cleanup function to destroy the Typed instance when the component unmounts
      return () => {
        typedRef.current.destroy();
      };
    }, []);
    


  return (
    <div>
        <div className='flex h-[80vh] bg-silver bg-opacity-15 justify-evenly intro-container'>
            <div className='pt-[10rem] text-left pl-[1rem] cursor-default intro_2'>
                <p className='text-6xl font-semibold font_1'>Transformative AI Solutions</p>
                <p className='text-5xl font-semibold text-Primarygreen pt-[1.5rem] font_2'>For Modern Enterprises</p>
                <p className='text-xl pt-[2rem] '>
                At Plutus Analytics, we believe in driving client success through the power of AI.
                <br/>
                Our mission is to harness advanced technology to <span className='text-xl pt-[2rem] role'>
                        
                        </span>
                </p>
                <div className='pt-[2rem]'>
                  <Link to="Register" smooth={true} duration={500}>
                      <div className="btn-pink rounded-md" id="btn">Join Us</div>
                  </Link>
                
                </div>
                
                
                
                
            </div>
            <div className='image-container w-[25vw]  pt-[8rem]'>
                <img src = {intro}></img>
            </div>
        </div>
    </div>
    
  )
}

export default Intro