import React from 'react'
import "./Footer.css"

import { RiTwitterXFill } from "react-icons/ri";
import { AiOutlineLinkedin } from "react-icons/ai";
import logo  from "../pics/plutus green.png"
import toast from 'react-hot-toast';

const Footer = () => {

    function twitterPop(){
        toast("We don't have any Twitter Handle right now.Feature Kept for future scope.")
    }

    const linkedIn = () => {
        window.open('https://www.linkedin.com/company/plutus-analytics/',"_blank"); // Replace with your LinkedIn profile URL
      };

  return (
    <div>
        <div className='flex justify-between h-[29vh] footer-container items-center '>
            <div className='footer_1 ml-[3rem] flex items-center'>
                <img alt="Plutus Logo" src={logo} className='w-[4rem]'></img>
                <p className='text-white text-bold text-xl'>Plutus</p>
            </div>
            <div className='footer_2 '>
                <div className='text-white text-xl mb-[1rem]'>
                    Get in Touch
                </div>
                <p className='text-white'>info@plutus.com</p>
                <p className='text-white ' ><a>sagrim7011@gmail.com</a></p>
                <p className='text-white mt-[1rem] mb-[1.5rem]'>+91 6006646819</p>
            </div>
            <div className='footer_3 mr-[3rem] text-xl'>
                <div className='text-white mb-[2rem] '>
                    Connect
                </div>
                <div className='flex mb-[3rem]'>

                    <RiTwitterXFill onClick={twitterPop} className='text-white mr-[1rem] hover:text-black cursor-pointer' />
                    <AiOutlineLinkedin onClick={linkedIn} className='text-white ml-[1rem] hover:text-black cursor-pointer'/>
                </div>
                
            </div>
        </div>
    </div>
  )
}

export default Footer