import React from 'react'
import "./Card.css"

const Card = (props) => {

    const service = props.service;

    
  return (
    <div>
        <div className='container bg-silver bg-opacity-20 w-[20rem] h-[15rem] overflow-hidden rounded-md flex flex-col pt-[1.6rem]'>
            <div className='service font-semibold'>{service.service}</div>
            <div className='description pt-[2rem] px-[1.7rem]'>{service.description}</div>

        </div>
    </div>
  )
}

export default Card