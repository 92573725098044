import React from 'react'
import services from '../data'
import Card from "./ui/Card"
import "./Services.css"


const Services = () => {
  return (
    <div>
        <div className='service-container bg-white pt-[1.7rem] cursor-default'>
            <div className='heading font-semibold'>
            AI Solutions Catered To Your Needs
            </div>
            <div className='description pt-[1rem] pb-[1rem]'>
            "At Plutus Analytics, we manage every aspect of your project journey—from initial ideation and development to meticulous monitoring and continuous maintenance. Our commitment ensures your complete satisfaction, leveraging AI to optimize operations and enhance customer engagement effectively."
            </div>
            <div className='card flex flex-wrap justify-center gap-x-[5rem] gap-y-[3rem] pt-[2rem]'>
              {
                services.map((service) => (
                <Card key={service.id} service={service}/>
              ))
              }
            </div>
        </div>
    </div>
  )
}

export default Services