const services = [
  {
      id: 1,
      service: "AI Consulting",
      description: "Strategic AI consulting to drive transformative innovation and operational efficiency, tailored to propel your business forward."
  },
  {
      id: 2,
      service: "Chatbot Development",
      description: "Crafting custom chatbot solutions that elevate customer interaction, streamline operations, and enhance user satisfaction."
  },
  {
      id: 3,
      service: "Financial Analysis and Fraud Detection",
      description: "Harnessing AI for advanced financial insights and proactive fraud detection, ensuring robust risk management and financial integrity."
  },
  {
      id: 4,
      service: "Supply Chain Optimization",
      description: "Deploying AI-driven strategies to optimize supply chain dynamics, boosting agility, efficiency, and resilience."
  },
  {
      id: 5,
      service: "RAG Application Development",
      description: "Building tailored RAG applications to fortify compliance efforts, mitigate risks, and uphold regulatory standards."
  },
  {
      id: 6,
      service: "Fine-Tuning LLM’s (Large Language Models)",
      description: "Expertly fine-tuning large language models for unparalleled accuracy and performance in data-driven applications."
  },
  // {
  //     id: 7,
  //     service: "AI-Driven Data Insights",
  //     description: "Transforming complex data into actionable insights with AI, empowering strategic decisions and fostering business growth."
  // }
];

export default services;