
import './App.css';
import Intro from './components/Intro';
import Navbar from './components/Navbar';
import Services from './components/Services';
import ContactForm from './components/ContactForm';
import { Element } from 'react-scroll';
import Footer from './components/Footer';

function App() {
  return (
    <div className="App">
      <Navbar/>

      <Element name='home'>
          <Intro/>
      </Element>

      <Element name='Services'>
      <Services />
      </Element>

      <Element name='Register'>
      <ContactForm />
      </Element>
      <Element name='Contact'>
        <Footer/>
      </Element>
      
      
      
    </div>
  );
}

export default App;
